import * as React from 'react';
import { AppBar, Grid, Box, Container, Toolbar, Typography, Button, Paper, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GitHub as GitHubIcon, LinkedIn as LinkedInIcon, Reddit as RedditIcon, Twitter as TwitterIcon } from '@mui/icons-material';
import CssBaseline from '@mui/material/CssBaseline';
import { CopyBlock } from "react-code-blocks";
import LicenseMateLogo from './assets/icons/licensemate.svg';
import DockerIcon from './components/DockerIcon';
import './Scrollbars.css';
import '@fontsource/staatliches';
import '@fontsource-variable/montserrat';
import '@fontsource/fira-mono';

function App() {

	const [tryMeDialogOpen, setTryMeDialogOpen] = React.useState(false);
	const theme = createTheme({
		typography: {
			fontFamily: [
				'"Montserrat Variable"',
			]
		},
		palette: {
			mode: 'dark',
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
				body: {
					backgroundImage: 'linear-gradient(179deg, rgba(3,19,33,1) 15%, rgba(26,43,67,1) 90%, rgba(37,53,75,1) 100%)',
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
				},
				},
			},
		},
		// this is for the code block theme
		backgroundColor: 'rgba(0,0,0,0.05)',
		textColor: '#e0e2e4',
		keywordColor: '#93c763',
		numberColor: '#ffcd22',
	})
	const socials = [
		{	
			name: 'GitHub',
			icon: <GitHubIcon />,
			url: 'https://github.com/yjpictures/license-mate'
		},
		{
			name: 'Docker Hub',
			icon: <DockerIcon />,
			url: 'https://hub.docker.com/r/yjpictures/license-mate'
		},
		{
			name: 'X (formerly Twitter)',
			icon: <TwitterIcon />,
			url: 'https://twitter.com/license_mate'
		},
		{
			name: 'LinkedIn',
			icon: <LinkedInIcon />,
			url: 'https://www.linkedin.com/showcase/license-mate'
		},
		{
			name: 'Reddit',
			icon: <RedditIcon />,
			url: 'https://www.reddit.com/user/license-mate'
		}
	];
	const dockerComposeCodeBlock = 
`name: license-mate

services:

	backend:
		image: yjpictures/license-mate:1.1
		container_name: backend
		ports:
			- 80:80
		environment:
			ADMIN_PWD: <admin_password>
			MANAGER_PWD: <manager_password>
			CLIENT_PWD: <client_password>
			REQUIRED_CREATE: name, email, company, product, machine-node, machine-sn
			UNIQUE_VALIDATE: email, machine-node, machine-sn
		networks:
			- backend-network
		depends_on:
			- database

	database:
		image: mongo:6.0
		container_name: database
		environment:
			MONGODB_DATA_DIR: /data/db
			MONDODB_LOG_DIR: /dev/null
		volumes:
			- licenseDB:/data/db
		networks:
			- backend-network

volumes:
	licenseDB:
		name: licenseDB
		driver: local

networks:
	backend-network:
		name: license-mate
		driver: bridge`

  return (
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<Box>
			<AppBar position="fixed" style={{ background: 'transparent', boxShadow: 'none'}}>
				<Toolbar>
					<img src={LicenseMateLogo} alt="logo" height='30vh' />
					<Typography variant="h5" sx={{ flexGrow: 1, fontFamily:'Staatliches' }}>
						License Mate
					</Typography>
					<Button 
						variant="contained"
						onClick={() => {
							fetch('https://sample.licensemate.ca/healthz');
							setTryMeDialogOpen(true);
						}}>
						Try Me
					</Button>
				</Toolbar>
			</AppBar>
			<Container maxWidth='xl'>
				<Grid container spacing={5}>
					<Grid item md={12}>
						<Box sx={{ height: '30vh' }} />
						<Box>
							<Typography variant='h1' sx={{ fontFamily:'Staatliches', color: '#01ddbe'}}>
								License Mate
							</Typography>
							<Typography variant='h4' sx={{ color: '#01ddbe' }}>
								SOFTWARE LICENSE MANAGER
							</Typography>
						</Box>
					</Grid>
					<Grid item md={3}>
						<Paper elevation={20} sx={{ p: 5, background: 'transparent' }}>
							{socials.map((item) => {
								return (
									<Tooltip title={item.name}>
										<IconButton href={item.url} target="_blank" alt={item.name} size="large">
											{item.icon}
										</IconButton>
									</Tooltip>
								)
							})}
						</Paper>
					</Grid>
					<Grid item md={9}>
						<Paper elevation={20} sx={{ p: 5, background: 'transparent' }}>
							<Typography variant='h4' paragraph>
								About
							</Typography>
							<Typography paragraph sx={{ lineHeight: 2 }}>
								License Mate is an open-source software license manager that you can deploy easily in a variety of environments and customize to your own needs. This is made using Flask for REST API server, React.js for the server UI and MongoDB for the database.
							</Typography>
							<Typography paragraph sx={{ lineHeight: 2 }}>
								License Mate is a tool or system designed to help organizations and individuals manage the distribution, tracking, and compliance of software licenses. It serves as a central hub for controlling how software licenses are allocated, monitored, and maintained for a software. Here's a breakdown of its key functions:
								<ol>
									<li>License Creation and Allocation</li>
									<li>License Tracking</li>
									<li>License Validation</li>
									<li>License Renewal</li>
									<li>License Deactivation and Removal</li>
								</ol>
							</Typography>
						</Paper>
					</Grid>
					<Grid item xl={6} sx={{ minWidth: 0 }}>
						<Paper elevation={20} sx={{ p: 5, background: 'transparent' }}>
							<Typography variant='h4' paragraph>
								Installation
							</Typography>
							<Typography paragraph sx={{ lineHeight: 2 }}>
								<ol>
									<li>Create the following docker compose file on your server and name it <code>compose.yml</code>.</li>
									<Typography sx={{ fontFamily: 'Fira Mono', fontSize: 11 }}>
										<CopyBlock
											text={dockerComposeCodeBlock}
											language='yml'
											showLineNumbers={true}
											theme={theme}
											codeBlock
										/>
									</Typography>
									<li>Customize the environment variables (lines 11-15) based on your needs.</li>
									<li>Run <code>docker compose up</code> in your server to pull the image and run it into a container.</li>
								</ol>
							</Typography>
							<Typography paragraph>
								Simple as that! License Mate server should now be running on your port 80.
							</Typography>
						</Paper>
					</Grid>
					<Grid item xl={6}>
						<Paper elevation={20} sx={{ p: 5, background: 'transparent' }}>
							<Typography variant='h4' paragraph>
								Interactive API Documentation
							</Typography>
							<Typography paragraph sx={{ lineHeight: 2 }}>
								Explore License Mate's REST API interactively by opening the server URL in your browser. The "Try Me" button at the top of the website provides live documentation, allowing you to test requests directly.
							</Typography>
							<Typography variant='h5' paragraph sx={{ marginTop: 5 }}>
								Authentication
							</Typography>
							<Typography paragraph sx={{ lineHeight: 2 }}>
								License Mate uses HTTP Basic Auth for secure API access. Include your credentials in the headers of your requests.
							</Typography>
							<Typography variant='h5' paragraph sx={{ marginTop: 5 }}>
								Admin Section
							</Typography>
							<Typography paragraph sx={{ lineHeight: 2 }}>
								Access the Admin section by navigating to <code>/ui</code> after the server URL. Admins can efficiently manage licenses through the web browser interface.
							</Typography>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Typography paragraph align='center'>
							Made with ❤️ by <Link href='https://www.yashj.ca' color='inherit'>Yash</Link>
						</Typography>
					</Grid>
				</Grid>
				<Dialog
					open={tryMeDialogOpen}
					onClose={() => setTryMeDialogOpen(false)}
				>
					<DialogTitle>License Mate Sample Sandbox</DialogTitle>
					<DialogContent>
						<DialogContentText paragraph>
							We have hosted a sample version of License Mate server which you could use to test out the APIs and other features.
						</DialogContentText>
						<DialogContentText paragraph>
							The URL for the server is sample.licensemate.ca
						</DialogContentText>
						<DialogContentText paragraph>
							Credentials for the server:
							<ol>
								<li>client - thisisclient</li>
								<li>manager - thisismanager</li>
								<li>admin - thisisadmin</li>
							</ol>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button href="https://sample.licensemate.ca/ui" target="_blank" onClick={() => setTryMeDialogOpen(false)}>Admin Control Panel</Button>
						<Button href="https://sample.licensemate.ca" target="_blank" onClick={() => setTryMeDialogOpen(false)}>Documentation</Button>
					</DialogActions>
				</Dialog>
			</Container>
		</Box>
	</ThemeProvider>
  );
}

export default App;
