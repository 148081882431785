import { SvgIcon } from '@mui/material';
import { ReactComponent as DockerLogo } from '../assets/icons/docker.svg';

const DockerIcon = () => {
	return(
		<SvgIcon>
			<DockerLogo />
		</SvgIcon>
	);
};

export default DockerIcon;
